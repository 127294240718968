import React from 'react';
import PostColumn from './PostColumn';
import { Link, graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';

const title = node => node.frontmatter.title || node.fields.slug;

const link = node => {
  const query = useStaticQuery(graphql`
    query {
      kitchen1: file(relativePath: { eq: "our-home/kitchen-1-sq.jpg" }) {
        ...squareFixedImage
      }
    }
  `);
  return (
    <Link style={{ boxShadow: 'none' }} to={node.fields.slug}>
      <Img fixed={query.kitchen1.childImageSharp.fixed} alt="kitchen" />
      <p>{title(node)}</p>
    </Link>
  );
};

export default PostColumn(link);
