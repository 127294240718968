import React from 'react';
import PostColumn from './PostColumn';
import { Link } from 'gatsby';
import Img from 'gatsby-image';

const title = node => node.frontmatter.title || node.fields.slug;

const link = node => (
  <Link className="" style={{ boxShadow: 'none' }} to={node.fields.slug}>
    <Img fixed={node.frontmatter.cover_image.childImageSharp.fixed} />
    <p>{title(node)}</p>
  </Link>
);

export default PostColumn(link);
