import React from 'react';
import Post from '../components/Post';
import StaticPost from '../components/StaticPost';
import '../assets/css/image-grid.css';
import './css/posts.css';

import { graphql, useStaticQuery } from 'gatsby';

export default () => {
  const query = useStaticQuery(graphql`
    query {
      posts: allMarkdownRemark(
        sort: { fields: [frontmatter___date], order: DESC }
      ) {
        edges {
          node {
            id
            excerpt
            fields {
              slug
            }
            frontmatter {
              date(formatString: "MMMM DD, YYYY")
              title
              cover_image {
                publicURL
                ...squareFixedImage
              }
            }
          }
        }
      }
    }
  `);

  const ourHousePost = {
    node: {
      id: '12039815123',
      firstPost: true,
      fields: {
        slug: '/our-home',
      },
      frontmatter: {
        title: 'Our home transfer',
        date: 'Jan 1, 2019',
      },
      excerpt: 'Our 1BR aparment (nest) in Plateau/Mont Royal.',
    },
  };

  const posts = query.posts.edges;
  return (
    <section name="posts">
      <ul className="image-grid"> {
        [ourHousePost, ...posts].map((post, index) => 
          post.node.firstPost 
            ? <StaticPost post={post} key={post.node.id + index} />
            : <Post post={post} key={post.node.id} />
        )
      } </ul>
    </section>
  );
};
